<template>
  <div class="auth-manage main-panel large">
    <h1 class="panel-title">权限管理</h1>
    <table-data :user-list="userList"
                :menu-list="menuList"
                :current-user="currentUser"
                @change-user="changeUser"
                @change-auth="changeAuth"
                v-loading="isLoading" />
  </div>
</template>

<script>
import TableData from "./TableData/TableData";

export default {
  components: {
    TableData
  },

  data() {
    return {
      userList: [],
      menuList: [],

      currentUser: null,
      isLoading: false
    }
  },

  async mounted() {
    await this.getUsers()
  },

  watch: {
    "$store.state.updateUser": function (flag) {
      flag && this.getUsers()
    }
  },

  methods: {
    async getUsers() {
      this.$showLoading();
      const res = await this.$api.Base.Auth.getUsers({}).catch(() => {})
      this.$hideLoading();
      if (res?.code === 200) {
        this.userList = res.data;
        this.changeUser(this.userList[0] .users[0].id);
      } else {
        this.$message.error(res?.msg || "获取用户列表失败");
      }
    },

    async getMenus(userId) {
      this.isLoading = true
      const res = await this.$api.Base.Auth.getMenus({userId, usertype: "customerstaffuser"}).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        res.data.data = res.data.data.map(i => {
          i.children = i.children.map(j => {
            j.permission = j.permission.reduce((prev, cur) => {
              prev[cur.perName.split(':')[1]] = {...cur, delflag: Boolean(cur.delflag)}
              return prev
            }, {})
            return j
          })
          return i
        })
        this.menuList = res.data
      } else {
        this.$message.error(res?.msg || "获取菜单失败")
      }
    },

    changeUser(id) {
      this.currentUser = id
      this.menuList = []
      this.getMenus(id)
    },

    async changeAuth(item) {
      // console.log(item)
      this.isLoading = true
      const sendData = {userId: item.userId, menuId: item.childrenId, content: item.childrenContent, name: item.perName, usertype: 'customerstaffuser'}
      const res = await this.$api.Base.Auth[item.flag ? 'add' : 'del'](sendData)

      // 每次修改这条数据的单选时都更新这条数据所有单选状态 (取消 [查询] 则取消 [增加，修改，删除] 权限，勾选 [增加，修改，删除] 则勾选 [查询] 权限)
      this.menuList.data = this.menuList.data.map(i => {
        i.children.map(child => {
          if (item.flag && !item.perName.includes('select')) {
            if (child.childrenId === item.childrenId && !child.permission['select'].delflag) {
              child.permission['select'].delflag = true;
              this.changeAuth({...item, perName: child.permission['select'].perName, flag: child.permission['select'].delflag});
            }
          } else if (!item.flag && item.perName.includes('select')) {
            if (child.childrenId === item.childrenId) {
              ['insert', 'update', 'delete'].map(did => {
                if (child.permission[did].delflag) {
                  child.permission[did].delflag = false;
                  this.changeAuth({...item, perName: child.permission[did].perName, flag: child.permission[did].delflag});
                }
              })
            }
          }
          return child;
        });
        return i;
      })

      this.isLoading = false
      if (res?.code === 200) {
        // this.$message({message: "授权成功", type: "success", duration: 600})
      } else {
        this.$message({message: "授权失败", type: "error", duration: 600})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./auth-manage";
</style>
