<template>
  <div class="table-data">
    <div class="tb-head">
      <div class="tb-th user-col cell">权限设置</div>
      <div class="tb-th menu-col cell">菜单功能</div>
      <div class="tb-th auth-col cell">查看</div>
      <div class="tb-th auth-col cell">新增</div>
      <div class="tb-th auth-col cell">修改</div>
      <div class="tb-th auth-col cell">删除</div>
    </div>
    <div class="tb-body">
      <ul class="user-list user-col">
        <li v-for="(item, index) in userList" :key="index">
          <span>{{ item.groupName }}</span>
          <ul class="sub-menu">
            <li v-for="user in item.users"
                :key="user.id"><span :class="{active: currentUser === user.id}" @click="changeUser(user.id)">{{ user.name }}</span></li>
          </ul>
        </li>
      </ul>
      <div class="menu-list">
        <table cellspacing="0">
          <tbody>
          <template v-for="item in menuList.data" :key="item.parentName">
            <tr>
              <td class="menu-col">{{ item.parentContent }}</td>
              <td class="auth-col"></td>
              <td class="auth-col"></td>
              <td class="auth-col"></td>
              <td class="auth-col"></td>
            </tr>
            <tr v-for="child in item.children" :key="child.childrenId" class="menu-item">
              <td class="menu-col">{{ child.childrenContent }}</td>
              <td class="auth-col">
                <el-checkbox v-model="child.permission['select'].delflag"
                             @change="flag => {changeAuth(menuList.userId, child.childrenId, child.childrenContent, child.permission['select'].perName, flag)}" />
              </td>
              <td class="auth-col">
                <el-checkbox v-model="child.permission['insert'].delflag"
                             @change="flag => {changeAuth(menuList.userId, child.childrenId, child.childrenContent, child.permission['insert'].perName, flag)}" />
              </td>
              <td class="auth-col">
                <el-checkbox v-model="child.permission['update'].delflag"
                             @change="flag => {changeAuth(menuList.userId, child.childrenId, child.childrenContent, child.permission['update'].perName, flag)}" />
              </td>
              <td class="auth-col">
                <el-checkbox v-model="child.permission['delete'].delflag"
                             @change="flag => {changeAuth(menuList.userId, child.childrenId, child.childrenContent, child.permission['delete'].perName, flag)}" />
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userList: Array,
    menuList: Array,
    currentUser: Number
  },

  methods: {
    changeUser(id) {
      if (this.currentUser === id) return false;
      this.$emit("changeUser", id)
    },

    changeAuth(userId, childrenId, childrenContent, perName, flag) {
      this.$emit("changeAuth", {userId, childrenId, childrenContent, perName, flag})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./TableData";
</style>
